jQuery(document).ready(function () {
    jQuery(".accordion-content").on(
      "show.bs.collapse",
      function () {
       // var id = jQuery(this).attr("data-service-id");
  
        // Hide all asides
        //jQuery(".card-aside").removeClass('inplace');
  
        // Show this aside
        //jQuery("#card-aside-" + id).addClass('inplace');
  
        var element_id = jQuery(this).attr("id");
        console.log(element_id);
        // This accordion
        var this_accordion_id = jQuery(this).attr("data-parent"); // has hash

        // Fidn the parent card and add active to it.
        jQuery(this_accordion_id + ' .card').removeClass('active');
        jQuery(this_accordion_id + ' .card').removeClass('inplace');
        jQuery('#' + element_id).parent().parent().addClass('active');

        // Add inplace after .2 seconds
        setTimeout(function () {
            jQuery('#' + element_id).parent().parent().addClass('inplace');
          }, 220);
        

      }
    );
  });



  jQuery(document).ready(function () {
    var age_gate = readCookie("age_gate");
    if (age_gate !== "yes") {
      jQuery(".age-gate-container").addClass("inplace");
      jQuery("body").addClass("age-gate");
    } else {
      console.log("Age gate passed");
    }
  
    jQuery("#age-gate-yes").click(function (event) {
      createCookie("age_gate", "yes", 30);
      jQuery(".age-gate-container").removeClass("inplace");
      jQuery("body").removeClass("age-gate");
  
      return false;
    });
  });
  


  jQuery(document).ready(function () {
    var store_state = readCookie("store_state");

    if (jQuery('.state-map').length > 0) {
      if (store_state == "" || store_state === null) {
        jQuery(".state-map-container").addClass("inplace");
        jQuery("body").addClass("age-gate");
        console.log('we here, showing map');
      } else {
        console.log("Store state: " + store_state);
      }
    
      jQuery(".state-map svg .st0").click(function (event) {
        var store_state = jQuery(this).attr('id');

        if (store_state != '') {
          createCookie("store_state", store_state, 30);
          jQuery(".state-map-container").removeClass("inplace");
          jQuery("body").removeClass("age-gate");
        }
    
        return false;
      });
    }
  });
  